import React from 'react';

import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import Header from './pages/Header';
import Footer from './pages/Footer';

import Routes from './Config/routes';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme.typography.h6 = {
  fontSize: '0.9rem',
  '@media (min-width:600px)': {
    fontSize: '1.1rem',
  },
}

theme.typography.h4 = {
  fontSize: '1.1rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
}

export default function App() {

  const classes = useStyles();

  return (

    <ThemeProvider theme={theme}>
      <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
        <div className={classes.root}>
          <Header/>
          <main className={classes.main}>
            <Routes/>
          </main>
          <footer className={classes.footer}>
            <Footer/>
          </footer>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
