import React from 'react';
import { CssBaseline } from '@material-ui/core';
import ReactDOM from 'react-dom';
import App from './App';
import { UnidadeProvider } from './Providers/unidadeProviders';

ReactDOM.render(
  <React.Fragment>
    <CssBaseline/>
    <UnidadeProvider>
      <App />
    </UnidadeProvider>
  </React.Fragment>,
  document.getElementById('root')
);