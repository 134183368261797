import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import { Alert } from '@material-ui/lab';
import { ArrowBack, ArrowForward, EventAvailable, Print } from '@material-ui/icons';

import PDFView from '../../Components/PDF';

import Selecao from './Selecao';
import Agenda from './Agenda';
import Review from './Review';
import Orientacoes from './Orientacoes';
import Tipo from './Tipo';

import { UnidadeContext } from '../../Providers/unidadeProviders';

import { useStyles } from './style';

import Service from './../../service';
import { Hidden } from '@material-ui/core';

const service = Service.getInstance();

const steps = ['orientações', 'tipo de atendimento', 'unidade e serviço', 'agendamento'];

// COMPONENTES
function getStepContent(step) {

  switch (step) {
    case 0:
      return <Orientacoes/>;
    case 1:
      return <Tipo/>;
    case 2:
      return <Selecao/>;
    case 3:
      return <Agenda/>;
    case 4:
      return <Review/>;
    default:
      throw new Error('Unknown step');
  }
  
}

export default function Agendamento() {

  const history = useHistory();

  const { state, checked, setState } = React.useContext(UnidadeContext);

  const [activeStep, setActiveStep] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  
  const [messageReturn, setMessageReturn] = React.useState({
    nome: '',
    slug: '',
    agendamento: {
      data: '',
      hora: '',
      servico: {
        descricao: '',
        unidade: {
          descricao: '',
          logradouro: '',
          numero: '',
          bairro: ''
        }
      }
    }
  });

  const handleNext = () => {

    if(activeStep === 1){
      setState({...state, servico_id: ''});
    }

    if(activeStep === steps.length -1){
      
      const store = async() => {
        
        setLoading(true);

        await service.post('atendimentos', state)
          .then(resp => {
            if(resp.status === 201){
              setActiveStep(activeStep + 1);
              setMessageReturn(resp.data.data);
              setState({...state, descricao_atendimento: ''});
            }
          })
          .catch(error => enqueueSnackbar(error.response.data.message, {variant: 'error'}));
        
        setLoading(false);

      }
      
      store();

    }else{

      setActiveStep(activeStep + 1);

    }

  };

  const handleBack = () => {
    if(activeStep === steps.length - 1){
      setState({...state, servico_id: ''});
    }
    setActiveStep(activeStep - 1);
  };

  const classes = useStyles();

  return (
    <div className={classes.layout}>
      
      <Fade in={true} timeout={700}>
      
        <Paper className={classes.paper}>
          
          {/* CABEÇAÇHO */}
          <Box display="flex" justifyContent="center">
            <Typography variant="overline" align="center">
              Iniciando agendamento
            </Typography>
          </Box>


          <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel><Typography variant="subtitle2" className={classes.titulo}>{label}</Typography></StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* CORPO */}
          <React.Fragment>
            
            {/* SE FOR O ULTIMO */}
            { activeStep === steps.length ? (
              <React.Fragment>
                <Container>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="h6" gutterBottom color="primary" align="center">
                        TUDO PRONTO!!!
                        <Divider style={{marginBottom: 20, marginTop: 20}}/>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="subtitle1" align="justify" className={classes.messageFinal}>
                        <Box lineHeight={2.5}>{`Senhor(a) ${messageReturn.nome}, 
                          seu protocolo de atendimento é ${messageReturn.slug}, e está agendado para o dia ${moment(messageReturn.agendamento.data).format('DD.MM.YYYY')} às 
                          ${messageReturn.agendamento.hora} na ${messageReturn.agendamento.servico.unidade.descricao} situada
                            na ${messageReturn.agendamento.servico.unidade.logradouro}, ${messageReturn.agendamento.servico.unidade.numero}, ${messageReturn.agendamento.servico.unidade.bairro}.`}
                            <Box><strong style={{color: 'red'}}>ATENÇAO: </strong> Para evitar aglomerações, por favor, <strong>CHEGUE COM 15 MINUTOS DE ANTECENDÊNCIA E USANDO MÁSCARA.</strong></Box></Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Alert severity="success" variant="outlined">Agendamento realizado com sucesso!</Alert>
                    </Grid>
                  </Grid>
                  <Toolbar/>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Button onClick={() => history.replace('/')} variant="outlined" color="default" size="small" startIcon={<EventAvailable/>}>
                        Principal
                      </Button>
                    </Box>
                    <Hidden xsDown>
                      <Box>
                        <Button variant="contained" color="primary" size="small" startIcon={<Print/>} style={{marginLeft: 4}} onClick={() => PDFView(messageReturn)}>
                          Imprimir
                        </Button>
                      </Box>
                    </Hidden>
                  </Box>
                </Container>
                
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* RENDERIZA OS COMPONENTES */}
                
                {getStepContent(activeStep)}
                
                {/* RENDERIZA OS BOTOES */}
                <div className={classes.buttons}>
                
                  {activeStep !== 0 ?
                    <Button onClick={handleBack} className={classes.button} size="small" variant="outlined" startIcon={<ArrowBack/>}>
                      Voltar
                    </Button>
                  : <Button onClick={() => history.replace('/')} className={classes.button} variant="outlined" color="default" size="small" startIcon={<EventAvailable/>}>
                      Página Principal
                    </Button>}
                  <Button
                    variant="contained"
                    color="secondary"
                    // onClick={activeStep === steps.length -1 ? submit : handleNext}
                    onClick={handleNext}
                    className={classes.button}
                    disabled={!checked || loading}
                    size="small"
                    endIcon={<ArrowForward/>}
                  >
                    {activeStep === steps.length - 1 ? loading ? <CircularProgress size={22} className={classes.buttonProgress} /> : 'Finalizar': 'Próximo'}
                  </Button>
                  
                </div>
                
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>  
      </Fade>    
        
    </div>
 
  );
}