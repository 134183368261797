import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    heroButtons: {
      // height: '50vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: 'auto',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    line: {
      marginBottom: '15px'
    },
    avatarLetf: {
      background: theme.palette.error.dark
    },
    avatarRigth: {
      background: theme.palette.primary.dark
    },
    cardHeade: {
      height: '100px'
    },
    large: {
      height: theme.spacing(5),
      width: theme.spacing(5)
    },
    avatarHelp: {
      width: theme.spacing(7),
      height: theme.spacing(7)
    },
    cart: {
      borderRadius: 5,
      border: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.grey[600],
      transition: "all 0.4s",
      "&:hover": {
        background: "#1976d2",
        color: "white",
        opacity: 1,
      }
    },
    avatarCard: {
      width: 200,
      [theme.breakpoints.down("sm")]: {
        width: 100,
      }
    }
}));