import api from "../Config/connection";

class Service {

    static myInstance = null;

    static getInstance(){
        if(Service.myInstance == null){
            Service.myInstance = new Service();
        }
        return this.myInstance;
    }
    
    get(url){
        return api.get(url);
    }

    async post(url, objeto){
        const response = await api.post(url, objeto);
        return response;
    }

    put(url, objeto){
        return api.put(url, objeto);
    }
    
    async delete(url, objeto){
        const resp = await api.delete(url, objeto);
        return resp;
    }

}

export default Service;