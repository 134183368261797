import axios from 'axios';

import { BASE_URL } from '../../Utils/Constants';

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        ContentType : 'application/json',
        Accept : 'application/json',
    },
    responseType: 'json',
    // timeout: 5000,
});

// Adicionar um interceptor de solicitação
api.interceptors.request.use(function (config) {

    // Faça algo antes que a solicitação seja enviada
    return config;
}, function (error) {

    // Faça algo com erro de solicitação
    return Promise.reject(error);
});

// Adicionar um interceptor de resposta
api.interceptors.response.use(function (response) {

    // Qualquer código de status dentro do intervalo de 2xx faz com que esta função seja acionada
    // Faça algo com os dados de resposta
    return response;
}, function (error) {

    // Quaisquer códigos de status que caiam fora do intervalo de 2xx fazem com que esta função seja acionada
    // Faça algo com erro de resposta

    return Promise.reject(error);
});

export default api;