import React from 'react';

import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';

// import { Container } from './styles';

export function FormatCPF(props) {

    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
            target: {
                name: props.name,
                value: values.value,
                
            },
            });
        }}
        thousandSeparator
        isNumericString
        format="###.###.###-##"
        mask="_"
        />
    );

}

export function NumberFormatPhone(props) {

    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
              
            },
          });
        }}
        thousandSeparator
        isNumericString
        min={11}
        format="(##)# ####-####"
      />
    );
  }

  export function TextMaskCustom(props){

    const { inputRef, ...other } = props;
      return (
        <MaskedInput
          {...other}
          ref={inputRef}
          mask={[
            /\w/,
            /\w/,
            /\w/,
            /\w/,
            '-',
            /\w/,
            /\w/,
            /\w/,
            /\w/,
            '-',
            /\w/,
            /\w/,
            /\w/,
            /\w/,
            '-',
            /\w/,
            /\w/,
            /\w/,
            /\w/,
          ]}
          placeholderChar={'\u2000'}
          showMask
        />
      );
  }