import React from 'react';
import { UnidadeContext } from './../../../Providers/unidadeProviders';
import { TextField, Typography, Grid, MenuItem, makeStyles, Box, Fade, Container, CircularProgress } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import Service from './../../../service';

import aviso from './../../../assets/warning-icon-transparent-free-png.webp';

const styles = makeStyles((theme) => ({
  combo: {
    fontSize: '0.8rem',
    '@media (min-width:600px)': {
      fontSize: '1rem',
    },
  },
  selecionado: {
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  naoSelecionado: {
    color: theme.palette.grey[600],
  },
}));

const service = Service.getInstance();

function Selecao() {

  const { enqueueSnackbar } = useSnackbar();

  const classes = styles();

  const { setChecked, state, setState } = React.useContext(UnidadeContext);

  const [secretarias, setSecretarias] = React.useState([]);
  const [secretaria, setSecretaria] = React.useState('');
  const [observacao, setObservacao] = React.useState('');

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {

    setOpen(true);

    const load = async () => {

      try {
        await service.get(`unidades?params=${Boolean(state.tipo_atendimento)}`).then(response => {
          const { data } = response.data;

          if (data) {
            setSecretarias(response.data.data);
          }

        }).catch(error => {
          console.log(error);
        });
      } catch (error) {
        enqueueSnackbar(error.response.statusText, { variant: 'error' });
      } finally {
        setOpen(false)
      }

    }

    load()

  }, [enqueueSnackbar, state.tipo_atendimento]);

  React.useEffect(() => {
    setChecked(false)
  }, [secretaria, setChecked]);

  const handleSecretaria = (e) => {
    try {
      setState({ ...state, servico_id: '' });
      setSecretaria(e);
    } catch (error) {
      enqueueSnackbar('Erro ao carregar as secretarias', { variant: 'error' });
    }
  }

  React.useEffect(() => {

    if (secretaria && state.servico_id) {
      const filtro = secretaria.lista_servicos.filter(item => item.id === state.servico_id).map(item => item.observacoes)
      setObservacao(filtro);
    } else {
      setObservacao('');
    }

  }, [state.servico_id, secretaria]);

  React.useEffect(() => {
    const verificaServico = () => {
      state.servico_id === '' ? setChecked(false) : setChecked(true);
    }
    verificaServico();
  }, [state, setChecked]);

  if (secretarias.length < 1 && !open) {
    return (
      <Box display="flex" alignItems="center" height={350}>
        <Box display={"flex"} alignItems={"center"} width={"100%"} flexDirection={"column"} justifyContent={"center"}>
          <img src={aviso} alt="aviso" width={100} />
          <Typography style={{ marginBottom: 5 }} align='center' variant='h4' color='textPrimary'>AVISO!</Typography>
          <Typography align='center' variant='body1' color='textSecondary'>Senhor usuário. Informamos que, no momento, os agendamentos para esse tipo de atendimento não estão disponíveis. Pedimos que tente novamente mais tarde. Agradecemos a sua compreensão.</Typography>
        </Box>
      </Box>
    )
  }

  if (open) {
    return (
      <Box display="flex" alignItems="center" height={350}>
        <Box display={"flex"} alignItems={"center"} width={"100%"} flexDirection={"column"} justifyContent={"center"}>
          <CircularProgress color='primary' size={20} />
          <Typography style={{ margin: 15 }} color='primary' variant='caption'>Buscando informações...</Typography>
        </Box>
      </Box>
    )
  }

  return (

    <Fade in={true} timeout={700}>

      <Container maxWidth="lg">
        <Grid container spacing={3}>

          <Grid item xs={12}>

            <Typography variant="h6" gutterBottom>
              Selecione a instituição
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              select
              fullWidth
              id="unidade_id"
              label="Instituição"
              name="unidade_id"
              size="small"
              value={secretaria}
              defaultValue={undefined}
              onChange={e => handleSecretaria(e.target.value)}
            >
              {
                secretarias.sort((a, b) => a.descricao > b.descricao ? 1 : -1).map((item, key) => (
                  <MenuItem key={key} value={item} className={classes.combo} dense={true}>
                    <Typography variant="body2" noWrap={true}>{item.descricao}</Typography>
                  </MenuItem>
                ))
              }
            </TextField>



            {secretaria && <Typography component="div" align="justify" color="primary">
              <Box display="flex" alignItems="center"><Box><LocationOn /></Box><Box fontSize={12}>{`${secretaria.logradouro}, ${secretaria.numero} - ${secretaria.bairro}`}</Box></Box>
            </Typography>}

          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Selecione o serviço
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              disabled={secretaria === '' || secretaria.lista_servicos.length < 1 || Boolean(secretaria && secretaria.lista_servicos.filter(item => item.interno !== Boolean(state.tipo_atendimento)).length > 0)}
              select
              fullWidth
              id="servico_id"
              label={Boolean(state.tipo_atendimento) ? 'Serviços (Público Privado)' : 'Serviços (Público em Geral)'}
              name="servico_id"
              size="small"
              value={state.servico_id}
              defaultValue=""
              onChange={(e) => setState({ ...state, 'servico_id': e.target.value })}
              error={Boolean(secretaria && secretaria.lista_servicos.filter(item => item.interno !== Boolean(state.tipo_atendimento)).length > 0)}
              helperText={secretaria && secretaria.lista_servicos.filter(item => item.interno !== Boolean(state.tipo_atendimento)).length > 0 && `Não há serviços destinado a este público para esta secretaria.`}
            >
              {
                secretaria ? secretaria.lista_servicos.filter(item => item.interno === Boolean(state.tipo_atendimento)).sort((a, b) => a.descricao > b.descricao ? 1 : -1).map((item) => (
                  <MenuItem key={item.id} value={item.id} className={classes.combo} dense={true}>
                    <Typography variant="body2" noWrap={true}>{item.descricao}</Typography>
                  </MenuItem>
                )) : <MenuItem value=""></MenuItem>
              }
            </TextField>

            <Typography component="div" align="justify" color="error">
              <Box ml={1} fontSize={12}>{observacao}</Box></Typography>

          </Grid>

          <Grid item xs={12}>

            <Typography variant="inherit" gutterBottom>
              Caso nencessário, nos informe mais detalhes do seu atendimento (opcional)
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="descricao_atendimento"
              label="Mais informações"
              name="descricao_atendimento"
              size="small"
              multiline
              rows={3}
              value={state.descricao_atendimento}
              placeholder="Descreva a sua solicitação"
              onChange={(e) => setState({ ...state, 'descricao_atendimento': e.target.value })}
            >

            </TextField>

          </Grid>

        </Grid>
      </Container>
    </Fade>
  );
}

export default React.memo(Selecao);