import React from 'react';
import { Container } from '@material-ui/core';
import Copyright from '../../Components/Copyright';

// import { Container } from './styles';

function Footer() {
  return (
    <Container maxWidth="sm">
        <Copyright />
    </Container>
  );
}

export default Footer;