import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export default function Header() {
    return (
      <AppBar position="relative" color='transparent' style={{boxShadow: "none", background: "#1976d2"}}>
        <Toolbar variant="dense"></Toolbar>
      </AppBar>
    );
}
