import React from 'react';

import { useHistory } from 'react-router-dom';

import { Avatar, Box, Container, Divider, Fade, Grid, Typography } from '@material-ui/core';

import { AccountBalance, Check, EventAvailable, HelpOutline, HowToReg, ListAlt, TouchApp } from '@material-ui/icons';

import { useStyles } from './slyles';

import logo from './../../assets/calendar.png'

import minha from './../../assets/minha.png'

function Home(props) {

    const history = useHistory();

    const classes = useStyles();

    const agendar = () => {
        props.history.push('/agendamento');
    }

    return (

        <Fade in={true} timeout={700}>
            <div>
                <Container component="main" maxWidth="md">
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img src="logo512.png" width="100" alt="logoPrincipal" />
                    </Box>

                    <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                        Agendamento Online
                    </Typography>
                    <Typography variant="body1" align="center" color="textSecondary" paragraph>
                        Agende seu atendimento a um de nossos serviços
                    </Typography>
                    <Divider style={{ marginBottom: 10 }} />
                    <Grid container spacing={1}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box onClick={agendar} style={{ cursor: "pointer", borderRadius: 5 }}>
                                <Box className={classes.cart}>
                                    <Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <img className={classes.avatarCard} alt="logo" src={logo} />
                                        <Box style={{ padding: 10, borderRadius: 5, width: "100%", textAlign: "end" }}>
                                            <Typography variant='h6'>AGENDAR</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box onClick={() => history.push('/meusagendamentos')} style={{ cursor: "pointer", borderRadius: 5 }}>
                                <Box className={classes.cart}>
                                    <Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <img className={classes.avatarCard} src={minha} alt='avatar'/>
                                        <Box style={{ padding: 10, borderRadius: 5, width: "100%", textAlign: "end" }}>
                                            <Typography variant='h6'>MINHA AGENDA</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Divider style={{ marginTop: 50 }} />
                <Container>
                    <Box className={classes.heroButtons}>
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box display="flex" flexDirection="column" alignItems="center" p={3}>
                                    <Box>
                                        <HelpOutline style={{ fontSize: 40 }} color="primary" />
                                    </Box>
                                    <Box p={1}>
                                        <Typography color="primary" variant="subtitle1" component="div"><Box fontWeight={600}>Como funciona?</Box></Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="primary" variant="subtitle2" align="center" component="div"><Box fontWeight={400}>Veja o passo a passo para agendar o seu atendimento em um de nossos serviços.</Box></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={12}>
                                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" p={2}>
                                    <Box>
                                        <Avatar style={{ backgroundColor: '#1976d2' }} className={classes.avatarHelp}><TouchApp /></Avatar>
                                    </Box>
                                    <Box m={2}>
                                        <Typography component="div" align="center" variant="caption"><Box>1º passo: Clique em AGENDAR.</Box></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={12}>
                                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" p={2}>
                                    <Box>
                                        <Avatar style={{ backgroundColor: '#7b1fa2' }} className={classes.avatarHelp}><ListAlt /></Avatar>
                                    </Box>
                                    <Box m={2}>
                                        <Typography component="div" align="center" variant="caption"><Box>2º passo: Leia com atenção as orientações gerais e clique em Li e concordo.</Box></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={12}>
                                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" p={2}>
                                    <Box>
                                        <Avatar style={{ backgroundColor: '#c62828' }} className={classes.avatarHelp}><HowToReg /></Avatar>
                                    </Box>
                                    <Box m={2}>
                                        <Typography component="div" align="center" variant="caption"><Box>3º passo: Selecione o tipo de atendimento. Lembre-se: Há dois tipos de atendimento. Serviço online e presencial.</Box></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={12}>
                                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" p={2}>
                                    <Box>
                                        <Avatar style={{ backgroundColor: '#e65100' }} className={classes.avatarHelp}><AccountBalance /></Avatar>
                                    </Box>
                                    <Box m={2}>
                                        <Typography component="div" align="center" variant="caption"><Box>4º passo: Selecione a unidade ao qual deseja o atendimento, bem como o serviço solicitado.</Box></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={12}>
                                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" p={2}>
                                    <Box>
                                        <Avatar style={{ backgroundColor: '#01579b' }} className={classes.avatarHelp}><EventAvailable /></Avatar>
                                    </Box>
                                    <Box m={2}>
                                        <Typography component="div" align="center" variant="caption"><Box>5º passo: Selecione a data e hora disponível.</Box></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} xs={12}>
                                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" p={2}>
                                    <Box>
                                        <Avatar style={{ backgroundColor: '#1b5e20' }} className={classes.avatarHelp}><Check /></Avatar>
                                    </Box>
                                    <Box m={2}>
                                        <Typography component="div" align="center" variant="caption"><Box>6º passo: Tudo pronto! Agendamento realizado com sucesso.</Box></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>
        </Fade>

    );
}

export default Home;