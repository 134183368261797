import React from 'react';
import 'react-day-picker/lib/style.css';
import { useSnackbar } from 'notistack';
import Helmet from 'react-helmet';

import DayPicker from 'react-day-picker';

import moment from 'moment';

import { Box, Container, Divider, Grid, Collapse, Paper, TextField, Typography, Backdrop, CircularProgress, Avatar, Button, Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AccessTime, ArrowBack, SentimentVeryDissatisfied } from '@material-ui/icons';

import {MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT} from './../../../Utils/DataConfig';

import { UnidadeContext } from '../../../Providers/unidadeProviders';

import { FormatCPF, NumberFormatPhone } from '../../../Components/FormatNumber';

import Service from '../../../service';

import { styles } from './styles';
import { useHistory } from 'react-router';

const service = Service.getInstance();

const modifiers = {
  disabledDays: [{
    after: new Date(1970, 0, 1),
    before: new Date(moment().add(1, 'd')),
  }]
};

const modifiersStyles = {
  disabledDays: {
    color: '#CFCFCF',
  },
};

function Agenda() {

  const { state, setChecked, setState } = React.useContext(UnidadeContext);

  const history = useHistory();

  React.useEffect(()=>{
    setChecked(false)
  }, [setChecked]);

  const { enqueueSnackbar } = useSnackbar();

  const data = new Date();

  const classes = styles();

  const [datas, setDatas] = React.useState([]);
  const [horas, setHoras] = React.useState([]);
  const [horaSelecionada, setHoraSelecionada] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [habilita, setHabilita] = React.useState(false);

  const [habilitaData, setHabilitaData] = React.useState(false);
  const [habilitaHora, setHabilitaHora] = React.useState(false);

  const [messageError, setMessageError] = React.useState({
    open: false,
    message: ''
  });

  const [selectedDay, setSelectedDay] = React.useState(null);

  React.useEffect(() => {

    const datasDisponiveis = async() => {
      
      try{
        
        setOpen(true);
      
        const response = await service.get(`datas/${state.servico_id}`).catch(error => enqueueSnackbar(error.response.statusText, {variant: 'error'}));
  
        const {data} = response;
  
        if(data){
          setDatas(() => response.data.map(item => {
            return new Date(moment(item.data).format('YYYY/MM/DD'));
          }))
        }else{
          setDatas([]);
        }

      }catch(error){
        enqueueSnackbar(error.response.statusText, {variant: 'error'});
      }finally{
        setOpen(false);
      }

    }

    datasDisponiveis();

  }, [enqueueSnackbar, state.servico_id]);

  React.useEffect(()=>{

    setChecked(false);

    if(datas.length > 0 && horas.length < 1){
      setHabilitaData(true);
      setHabilitaHora(false)
      setHabilita(false);
    }else if(datas.length > 0 && horas.length > 0){
      setHabilitaData(false);
      setHabilitaHora(true)
      setHabilita(false);
    }
  },[datas, horas, setChecked]);

  const formatData = React.useCallback((day, modifiers) => {

    setState({...state, nome: '', cpf: '', email: '', fone: ''});

    if(modifiers.disabled || modifiers.disabledDays){
      setSelectedDay(null);
      return;
    }
    setSelectedDay(day);

    setOpen(true);
    setMessageError({})

    const horariosDisponiveis = async() => {
      
      try{

        await service.get(`horas?data=${moment(day).format('YYYY-MM-DD')}&servico_id=${state.servico_id}`).then(res => {
          setHoras(res.data);
        });

      }catch(error){

        setHoras([]);

        if(error.response.status === 404){
          setMessageError({...messageError, open: true, message: error.response.data});
          enqueueSnackbar('Não existe agendamento para a data selecionada!', {variant: 'info'});
        }
        if(error.response.status === 500){
          enqueueSnackbar('Internal Server Error', {variant: "error"});
        }
        
      }finally{
        setOpen(false);
      }
    }

    horariosDisponiveis();

  }, [state, setState, enqueueSnackbar, messageError, setOpen]);

  const handleInput = (target, value) => {
    setState({...state, [target]: value});
  }

  const handleSelecionaHora = (e, hora) => {
    
    const anchor = (e.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    setChecked(true);
    setHabilita(true);
    setState({...state, tempo_servicos_id: hora.id});
    setHoraSelecionada(hora.hora);
    
  }

  if(!open){
    if(datas && datas.length < 1){
      return (
        <Paper elevation={0}>
          <Typography component="span" align="center" variant="body2" color="error">
            <Box display="flex" justifyContent="center" pl={4} pr={4} pb={1} pt={4}><Avatar className={classes.error}><SentimentVeryDissatisfied/></Avatar></Box>
            <Box display="flex" justifyContent="center" pl={4} pr={4} pb={1} pt={1} fontWeight="fontWeightBold">
              Não há datas disponíveis para agendamento à esta secretaria. Caso necessário agendar em outra secretaria, clique em voltar!
            </Box>
            <Box pl={4} pr={4} pb={6} pt={1}><Button onClick={() => history.replace('/')} variant="outlined" color="secondary" size="small">Encerrar</Button></Box>
            <Divider/>
          </Typography>
        </Paper>
      )
    }
  }
  
  return (
    <Fade in={true} timeout={700}>
      <Paper>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Collapse in={habilitaData} >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: "flex", justifyContent: "center"}}>
              <Typography variant="overline" component="div" color="error"><Box fontWeight="fontWeightBold">Selecione uma data em destaque</Box></Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: "flex", justifyContent: "center"}}>
              <Box display="flex">
                <Typography color="error" component="div"><Box fontWeight="fontWeightBold" fontSize={13} p={1}>Legenda:</Box></Typography>
                <Box p={1}><span className={classes.bola}></span></Box>
                <Box p={1}><Typography variant="caption" component="div" color="textSecondary"><Box fontSize={13} fontWeight="fontWeightBold">Datas disponíveis</Box></Typography></Box>
              </Box>
            </Grid>
            
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Helmet>
                  <style>{`
                    .DayPicker{
                      font-size: 0.85rem;
                      display: flex;
                      justify-content: center;
                    }
                    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                      background-color: #9e9e9e;
                      color: #FFF;
                      font-weight: 500;
                      width: 33px;
                      height: 33px;
                      border-radius: 50%;
                    }
                    `}</style>
                </Helmet>
                <DayPicker
                  numberOfMonths={2} pagedNavigation fixedWeeks
                  initialMonth={new Date(data.getFullYear(), data.getMonth())}
                  months={MONTHS}
                  weekdaysLong={WEEKDAYS_LONG}
                  weekdaysShort={WEEKDAYS_SHORT}
                  selectedDays={datas || selectedDay}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  disabledDays={modifiers.selectedDays}
                  onDayClick={formatData}
                />
              </Grid >
            
          </Grid>
        </Collapse>

        <Collapse in={habilitaHora} >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" justifyContent="center">
                <Typography variant="overline" component="div" color="error"><Box fontWeight="fontWeightBold">Selecione um dos horários abaixo</Box></Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" justifyContent="center">
                <Typography color="error" component="div"><Box fontWeight="fontWeightBold" fontSize={13} p={1}>Legenda:</Box></Typography>
                <Box p={1}>
                  <Typography variant="caption" component="div" color="textSecondary">
                    <Box fontSize={13} fontWeight="fontWeightBold">
                      <AccessTime/>
                    </Box>
                  </Typography>
                </Box>
                <Box p={1}>
                  <Typography variant="caption" component="div" color="textSecondary">
                    <Box fontSize={13} fontWeight="fontWeightBold">Horários disponíveis</Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" flexWrap="wrap" justifyContent="center" >
                {
                  messageError.open ? 
                    <Typography component="div" color="error" align="center">
                      <Box fontWeight="fontWeightBold" m={1}>
                        {messageError.message}
                      </Box>
                    </Typography> : 
                      horas.map((hora) => 
                        <Box key={hora.id}>

                          <Box onClick={(event) => handleSelecionaHora(event, hora)} m={1}>
                            <Avatar className={classes.hora}>
                              <AccessTime/>
                            </Avatar>
                            <Typography component="div" variant="overline" color="textSecondary" align="center">
                              <Box fontSize={13} fontWeight="fontWeightBold">{hora.hora.substring(0, hora.hora.length - 3)}</Box>
                            </Typography>
                          </Box>
                        </Box>
                      )
                    
                }
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" justifyContent="center" pt={2} pb={2}>
                <Button size="small" variant="outlined" color="primary" onClick={() => setHoras([])} startIcon={<ArrowBack/>}>Voltar para data</Button>
              </Box>
            </Grid>
          </Grid>
        </Collapse>
        
        <Container>
          <Collapse in={habilita}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Typography component="div" align="center" variant="overline">
                  <Box fontWeight="fontWeightBold">Dados pessoais</Box>
                  <Divider/>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                {habilita && <Alert severity="success" variant="outlined">
                  Você está agendando seu atendimento para o dia <strong>{moment(selectedDay).format('DD/MM/YYYY')}</strong> às <strong>{horaSelecionada.substring(0, horaSelecionada.length - 3)}</strong>. Prossiga com seu cadastro e finalize seu agendamento! 
                </Alert>}
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoFocus={true}
                  required
                  id="name"
                  variant="outlined"
                  size="small"
                  name="nome"
                  label="Nome"
                  fullWidth
                  value={state.nome}
                  onChange={(e) => handleInput('nome', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  id="cpf"
                  variant="outlined"
                  size="small"
                  name="cpf"
                  label="CPF"
                  fullWidth
                  value={state.cpf}
                  onChange={(e) => handleInput('cpf', e.target.value)}
                  InputProps={{
                    inputComponent: FormatCPF,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
              <div id="back-to-top-anchor"/>
                <TextField
                  id="email"
                  variant="outlined"
                  size="small"
                  name="email"
                  label="Email"
                  value={state.email}
                  onChange={(e) => handleInput('email', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} style={{marginBottom: '50px'}}>
                <TextField
                  required
                  id="fone"
                  name="fone"
                  variant="outlined"
                  size="small"
                  label="Celular (WhatsApp)"
                  fullWidth
                  value={state.fone}
                  onChange={(e) => handleInput('fone', e.target.value)}
                  helperText="Informe um telefone para contato. De preferência WhatsApp."
                  InputProps={{
                    inputComponent: NumberFormatPhone,
                  }}
                />
                
              </Grid>
            </Grid>
          </Collapse></Container>
      </Paper>
    </Fade>
  );
}

export default React.memo(Agenda);
