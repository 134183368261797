import React from 'react';

import {BrowserRouter, Switch, Route} from 'react-router-dom';
import PDF from '../../Components/PDF';
// import Manutencao from '../../pages/Manutencao';

import MeusAgendamentos from '../../pages/MeusAgendamentos';
import Page404 from '../../pages/page404';
import Agendamento from './../../pages/Agendamento';
import Home from './../../pages/Home';

const routes = () => {

    return (
        <BrowserRouter>
            <Switch>
                {/* <Route exact path="/" component={Manutencao} /> */}
                <Route exact path="/" component={Home} />
                <Route exact path="/agendamento" component={Agendamento} />
                <Route exact path="/meusagendamentos" component={MeusAgendamentos}/>
                <Route exact path="/pdf" component={PDF} />
                {/* <Route exact path="/teste" component={Teste}/> */}
                <Route path="*" component={Page404} />
            </Switch>
        </BrowserRouter>
    )

}

export default routes;