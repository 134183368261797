import React from 'react';

export const UnidadeContext = React.createContext({});

const data = {
    nome: '', 
    cpf: '', 
    email: '', 
    fone: '',
    servico_id: '',
    descricao_atendimento: '',
    tempo_servicos_id: '',
    tipo_atendimento: false
}

export const UnidadeProvider = (props) => {

    const [state, setState] = React.useState(data);

    /*
     * O checked é utilizado para habilitar e desabilitar o botão (próximo) através do botão checked no componente Orientações
     */
    const [checked, setChecked] = React.useState(false);

    return (
        <UnidadeContext.Provider value={{
            checked, 
            setChecked,
            state,
            setState,
        }}>

            {props.children}

        </UnidadeContext.Provider>
    )

}