import { makeStyles } from "@material-ui/core";

export const styles = makeStyles((theme) => ({

    buton: {
      margin: theme.spacing(0.3)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    bola: {
      borderRadius: '50%',
      display: 'inline-block',
      height: '20px',
      width: '20px',
      // backgroundColor: '#4A90E2',
      background: '#9e9e9e'
    },
    error: {
      background: theme.palette.error.main
    },
    hora: {
      color: theme.palette.common.white,
      background: theme.palette.grey[400],
      cursor: 'pointer',
      "&:hover":{
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
      }
    }
}));