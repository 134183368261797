import React from 'react';

import { Paper, Avatar, Box, Container, Grid, Link, Typography, makeStyles, Fade } from '@material-ui/core';
import { CheckBox, AssignmentInd, PeopleAlt } from '@material-ui/icons';
import { UnidadeContext } from '../../../Providers/unidadeProviders';

import Service from '../../../service';

const api = Service.getInstance();

const styles = makeStyles((theme) => ({
    selecionado: {
        background: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    naoSelecionado: {
        color: theme.palette.grey[600],
    },
}));

function Tipo() {

    const { state, setState, setChecked } = React.useContext(UnidadeContext);

    const classes = styles();

    const [check, setCheck] = React.useState(Boolean(state.tipo_atendimento));

    const [servicos, setServicos] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setChecked(Boolean(true));
    }, [setChecked]);

    const checkar = (value) => {
        setCheck(value);
        setState({ ...state, tipo_atendimento: Boolean(value) });
    };

    React.useEffect(() => {

        (async () => {

            try {

                setLoading(true)

                const response = await api.get('servicos');

                console.log(response)

                setServicos(response.data);

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false)
            }

        })()

    }, [])

    return (
        <Fade in={true} timeout={700}>
            <Container style={{ minHeight: '40vh' }}>

                <Box display="flex" justifyContent="center">
                    <Box>
                        <Typography component="div" variant="overline"><Box fontWeight={600} fontSize={22}>Tipo de atendimento</Box></Typography>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Box mb={3}>
                        <Typography component="div" variant="body2">Selecione o tipo de atendimento que desejar!</Typography>
                    </Box>
                </Box>

                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Link onClick={() => checkar(false)} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                            <Paper className={check === false ? classes.selecionado : classes.naoSelecionado} variant="outlined">
                                <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                                    <Box p={1}>
                                        <Avatar><PeopleAlt /></Avatar>
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Typography component="div" variant="overline" align="right"><Box fontWeight={600} fontSize={13}>{"Online"}</Box></Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="div" variant="caption" align="right"><Box fontSize={11}>{"Atendimento por video chamada"}</Box></Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Link>
                        <Box mt={1} height="100%">
                            <Box ml={1}><Typography color="textSecondary" variant="caption">Servicos disponíveis:</Typography></Box>
                            {servicos.online?.map(servico => (
                                <Box m={0.5} display="flex" alignItems="center" style={{ background: 'green', borderRadius: 5, color: 'white', padding: 3 }} key={servico.id}><CheckBox fontSize="small" /><Typography variant="caption">{servico.descricao}</Typography></Box>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Link onClick={() => checkar(true)} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                            <Paper className={check === true ? classes.selecionado : classes.naoSelecionado} variant="outlined">
                                <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                                    <Box p={1}>
                                        <Avatar><AssignmentInd /></Avatar>
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Typography component="div" variant="overline" align="right"><Box fontWeight={600} fontSize={13}>{"Presencial"}</Box></Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="div" variant="caption" align="right"><Box fontSize={11}>{"Atendimento presencial"}</Box></Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Link>
                        <Box mt={1} height="100%">
                            <Box ml={1}><Typography color="textSecondary" variant="caption">Servicos disponíveis:</Typography></Box>
                            {servicos.presencial?.map(servico => (
                                <Box m={0.5} display="flex" alignItems="center" style={{ background: 'green', borderRadius: 5, color: 'white', padding: 3 }} key={servico.id}><CheckBox fontSize="small" /><Typography variant="caption">{servico.descricao}</Typography></Box>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
                <Box p={1}>{loading && <Typography variant="caption">Carregando...</Typography>}</Box>
            </Container>
        </Fade>
    );
}

export default Tipo;