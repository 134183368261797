import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

import { informativo } from '../Informativo';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export default function PDFView(props){

  const {vfs} = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  const documentDefinition = {
    footer: function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
    header: function(currentPage, pageCount, pageSize) {
      // you can apply any logic and return any valid pdfmake element
      return [
        { text: 'Agendamento online', alignment: (currentPage % 2) ? 'left' : 'right' },
        { canvas: [ { type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 40 } ] }
      ]
    },
    pageSize: 'A4',
    pageOrientation: 'portrait',
    fontLayoutCache: true,
    content: 
    [
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="454px" height="340px" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g><path style="opacity:0.973" fill="#0c3e6e" d="M 168.5,84.5 C 168.5,87.8333 168.5,91.1667 168.5,94.5C 155.833,94.5 143.167,94.5 130.5,94.5C 130.5,149.167 130.5,203.833 130.5,258.5C 206.998,259.702 283.331,259.702 359.5,258.5C 372.5,258.5 385.5,258.5 398.5,258.5C 398.5,203.833 398.5,149.167 398.5,94.5C 385.5,94.5 372.5,94.5 359.5,94.5C 359.5,91.1667 359.5,87.8333 359.5,84.5C 375.17,84.3334 390.837,84.5001 406.5,85C 407,85.5 407.5,86 408,86.5C 408.5,147.166 408.667,207.832 408.5,268.5C 312.5,268.5 216.5,268.5 120.5,268.5C 120.833,222.828 120.5,177.162 119.5,131.5C 87.2512,145.215 59.7512,138.882 37,112.5C 23.4414,91.9091 20.7747,69.9091 29,46.5C 43.8669,16.4799 68.0335,3.31325 101.5,7C 129.447,12.9469 146.947,29.7802 154,57.5C 155.722,66.4867 155.889,75.4867 154.5,84.5C 159.167,84.5 163.833,84.5 168.5,84.5 Z"/></g>
          <g><path style="opacity:1" fill="#fcfcfd" d="M 88.5,37.5 C 87.8106,47.4013 87.144,57.4013 86.5,67.5C 84.124,68.512 83.124,70.3454 83.5,73C 83.3536,75.0342 84.0203,76.7009 85.5,78C 89.1939,78.2779 92.8606,78.2779 96.5,78C 104.742,81.7049 112.909,85.5382 121,89.5C 121.667,97.8333 121.667,106.167 121,114.5C 108.037,124.082 93.5368,127.249 77.5,124C 43.8124,111.278 31.6458,87.4447 41,52.5C 53.888,26.4723 74.7213,15.9723 103.5,21C 132.821,32.1314 145.321,53.2981 141,84.5C 134.553,85.2244 128.053,85.8911 121.5,86.5C 113.054,83.111 104.721,79.4443 96.5,75.5C 95.6922,73.5767 95.1922,71.5767 95,69.5C 94.3109,68.6433 93.4776,67.9767 92.5,67.5C 91.5014,57.1881 91.1681,46.8548 91.5,36.5C 90.209,36.2627 89.209,36.596 88.5,37.5 Z"/></g>
          <g><path style="opacity:1" fill="#ca5e52" d="M 86.5,22.5 C 94.8412,21.8734 97.1746,25.2067 93.5,32.5C 84.4193,34.1916 82.0859,30.8582 86.5,22.5 Z"/></g>
          <g><path style="opacity:1" fill="#456d91" d="M 88.5,37.5 C 89.141,47.8893 88.4743,57.8893 86.5,67.5C 87.144,57.4013 87.8106,47.4013 88.5,37.5 Z"/></g>
          <g><path style="opacity:0.932" fill="#093d6c" d="M 359.5,54.5 C 295.833,54.5 232.167,54.5 168.5,54.5C 167.271,49.2581 168.937,45.4248 173.5,43C 233.833,42.3333 294.167,42.3333 354.5,43C 359.063,45.4248 360.729,49.2581 359.5,54.5 Z"/></g>
          <g><path style="opacity:1" fill="#b85650" d="M 331.5,45.5 C 334.966,46.109 335.966,47.9423 334.5,51C 329.289,51.5464 328.289,49.713 331.5,45.5 Z"/></g>
          <g><path style="opacity:1" fill="#bf574f" d="M 339.5,45.5 C 341.484,45.484 342.484,46.484 342.5,48.5C 342.566,50.2664 341.732,51.2664 340,51.5C 336.911,49.7956 336.745,47.7956 339.5,45.5 Z"/></g>
          <g><path style="opacity:1" fill="#be574f" d="M 346.5,45.5 C 350.35,45.7007 351.35,47.5341 349.5,51C 345.537,51.1177 344.537,49.2843 346.5,45.5 Z"/></g>
          <g><path style="opacity:0.999" fill="#fefdfe" d="M 168.5,54.5 C 232.167,54.5 295.833,54.5 359.5,54.5C 359.5,64.5 359.5,74.5 359.5,84.5C 359.5,87.8333 359.5,91.1667 359.5,94.5C 359.5,149.167 359.5,203.833 359.5,258.5C 296.167,258.5 232.833,258.5 169.5,258.5C 169.832,200.331 169.499,142.331 168.5,84.5C 168.5,74.5 168.5,64.5 168.5,54.5 Z"/></g>
          <g><path style="opacity:1" fill="#ca5d52" d="M 41.5,67.5 C 46.7064,66.1199 49.7064,67.9532 50.5,73C 48.5799,78.7412 45.0799,79.9079 40,76.5C 39.068,73.1769 39.568,70.1769 41.5,67.5 Z"/></g>
          <g><path style="opacity:1" fill="#ca5d52" d="M 130.5,67.5 C 135.706,66.1199 138.706,67.9532 139.5,73C 137.58,78.7412 134.08,79.9079 129,76.5C 128.068,73.1769 128.568,70.1769 130.5,67.5 Z"/></g>
          <g><path style="opacity:1" fill="#d8857c" d="M 86.5,90.5 C 86.1632,86.6924 86.6632,83.0257 88,79.5C 88.5666,83.4168 88.0666,87.0834 86.5,90.5 Z"/></g>
          <g><path style="opacity:1" fill="#2889c7" d="M 168.5,84.5 C 169.499,142.331 169.832,200.331 169.5,258.5C 232.833,258.5 296.167,258.5 359.5,258.5C 283.331,259.702 206.998,259.702 130.5,258.5C 130.5,203.833 130.5,149.167 130.5,94.5C 143.167,94.5 155.833,94.5 168.5,94.5C 168.5,91.1667 168.5,87.8333 168.5,84.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 232.5,95.5 C 237.833,95.5 243.167,95.5 248.5,95.5C 248.5,100.833 248.5,106.167 248.5,111.5C 243.167,111.5 237.833,111.5 232.5,111.5C 232.5,106.167 232.5,100.833 232.5,95.5 Z"/></g>
          <g><path style="opacity:1" fill="#d4d4d4" d="M 255.5,94.5 C 261.167,94.5 266.833,94.5 272.5,94.5C 272.5,100.167 272.5,105.833 272.5,111.5C 266.833,111.5 261.167,111.5 255.5,111.5C 255.5,105.833 255.5,100.167 255.5,94.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 279.5,95.5 C 284.833,95.5 290.167,95.5 295.5,95.5C 295.5,100.833 295.5,106.167 295.5,111.5C 290.167,111.5 284.833,111.5 279.5,111.5C 279.5,106.167 279.5,100.833 279.5,95.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 303.5,95.5 C 308.833,95.5 314.167,95.5 319.5,95.5C 319.5,100.833 319.5,106.167 319.5,111.5C 314.167,111.5 308.833,111.5 303.5,111.5C 303.5,106.167 303.5,100.833 303.5,95.5 Z"/></g>
          <g><path style="opacity:1" fill="#c8594d" d="M 326.5,95.5 C 331.833,95.5 337.167,95.5 342.5,95.5C 342.5,100.833 342.5,106.167 342.5,111.5C 337.167,111.5 331.833,111.5 326.5,111.5C 326.5,106.167 326.5,100.833 326.5,95.5 Z"/></g>
          <g><path style="opacity:1" fill="#2088c8" d="M 359.5,94.5 C 372.5,94.5 385.5,94.5 398.5,94.5C 398.5,149.167 398.5,203.833 398.5,258.5C 385.5,258.5 372.5,258.5 359.5,258.5C 359.5,203.833 359.5,149.167 359.5,94.5 Z"/></g>
          <g><path style="opacity:1" fill="#d88379" d="M 86.5,90.5 C 86.7842,96.6594 85.9509,102.659 84,108.5C 83.774,102.251 84.6073,96.251 86.5,90.5 Z"/></g>
          <g><path style="opacity:1" fill="#cb5f54" d="M 87.5,111.5 C 95.9568,112.437 97.6235,116.27 92.5,123C 85.8336,123.996 83.3336,121.163 85,114.5C 86.045,113.627 86.8783,112.627 87.5,111.5 Z"/></g>
          <g><path style="opacity:1" fill="#c8594d" d="M 185.5,116.5 C 190.833,116.5 196.167,116.5 201.5,116.5C 201.5,121.833 201.5,127.167 201.5,132.5C 196.167,132.5 190.833,132.5 185.5,132.5C 185.5,127.167 185.5,121.833 185.5,116.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 208.5,116.5 C 213.833,116.5 219.167,116.5 224.5,116.5C 224.5,121.833 224.5,127.167 224.5,132.5C 219.167,132.5 213.833,132.5 208.5,132.5C 208.5,127.167 208.5,121.833 208.5,116.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 232.5,116.5 C 237.833,116.5 243.167,116.5 248.5,116.5C 248.5,121.833 248.5,127.167 248.5,132.5C 243.167,132.5 237.833,132.5 232.5,132.5C 232.5,127.167 232.5,121.833 232.5,116.5 Z"/></g>
          <g><path style="opacity:1" fill="#d3d3d3" d="M 255.5,116.5 C 261.167,116.5 266.833,116.5 272.5,116.5C 272.5,121.833 272.5,127.167 272.5,132.5C 266.833,132.5 261.167,132.5 255.5,132.5C 255.5,127.167 255.5,121.833 255.5,116.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 279.5,116.5 C 284.833,116.5 290.167,116.5 295.5,116.5C 295.5,121.833 295.5,127.167 295.5,132.5C 290.167,132.5 284.833,132.5 279.5,132.5C 279.5,127.167 279.5,121.833 279.5,116.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 303.5,116.5 C 308.833,116.5 314.167,116.5 319.5,116.5C 319.5,121.833 319.5,127.167 319.5,132.5C 314.167,132.5 308.833,132.5 303.5,132.5C 303.5,127.167 303.5,121.833 303.5,116.5 Z"/></g>
          <g><path style="opacity:1" fill="#c8594d" d="M 326.5,116.5 C 331.833,116.5 337.167,116.5 342.5,116.5C 342.5,121.833 342.5,127.167 342.5,132.5C 337.167,132.5 331.833,132.5 326.5,132.5C 326.5,127.167 326.5,121.833 326.5,116.5 Z"/></g>
          <g><path style="opacity:1" fill="#c8594d" d="M 185.5,137.5 C 190.833,137.5 196.167,137.5 201.5,137.5C 201.5,142.833 201.5,148.167 201.5,153.5C 196.167,153.5 190.833,153.5 185.5,153.5C 185.5,148.167 185.5,142.833 185.5,137.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 208.5,137.5 C 213.833,137.5 219.167,137.5 224.5,137.5C 224.5,142.833 224.5,148.167 224.5,153.5C 219.167,153.5 213.833,153.5 208.5,153.5C 208.5,148.167 208.5,142.833 208.5,137.5 Z"/></g>
          <g><path style="opacity:1" fill="#d2d1d1" d="M 247.5,154.5 C 242.804,151.921 238.137,151.921 233.5,154.5C 232.548,148.925 232.214,143.259 232.5,137.5C 237.833,137.5 243.167,137.5 248.5,137.5C 248.786,143.259 248.452,148.925 247.5,154.5 Z"/></g>
          <g><path style="opacity:1" fill="#d3d3d3" d="M 255.5,137.5 C 261.167,137.5 266.833,137.5 272.5,137.5C 272.5,142.833 272.5,148.167 272.5,153.5C 266.833,153.5 261.167,153.5 255.5,153.5C 255.5,148.167 255.5,142.833 255.5,137.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 279.5,137.5 C 284.833,137.5 290.167,137.5 295.5,137.5C 295.5,142.833 295.5,148.167 295.5,153.5C 290.167,153.5 284.833,153.5 279.5,153.5C 279.5,148.167 279.5,142.833 279.5,137.5 Z"/></g>
          <g><path style="opacity:1" fill="#d1d2d1" d="M 303.5,137.5 C 308.833,137.5 314.167,137.5 319.5,137.5C 319.5,142.833 319.5,148.167 319.5,153.5C 314.167,153.5 308.833,153.5 303.5,153.5C 303.5,148.167 303.5,142.833 303.5,137.5 Z"/></g>
          <g><path style="opacity:1" fill="#c8594d" d="M 326.5,137.5 C 331.833,137.5 337.167,137.5 342.5,137.5C 342.5,142.833 342.5,148.167 342.5,153.5C 337.167,153.5 331.833,153.5 326.5,153.5C 326.5,148.167 326.5,142.833 326.5,137.5 Z"/></g>
          <g><path style="opacity:1" fill="#ca6056" d="M 247.5,154.5 C 255.18,160.567 256.513,167.9 251.5,176.5C 249.965,175.646 248.632,175.646 247.5,176.5C 246.903,176.265 246.57,175.765 246.5,175C 250.784,169.339 250.451,164.005 245.5,159C 237.81,156.01 233.143,158.51 231.5,166.5C 231.985,173.318 235.652,176.651 242.5,176.5C 242.5,177.833 242.5,179.167 242.5,180.5C 238.838,180.966 235.505,180.299 232.5,178.5C 226.122,172.844 224.956,166.177 229,158.5C 230.371,156.959 231.871,155.625 233.5,154.5C 238.137,151.921 242.804,151.921 247.5,154.5 Z"/></g>
          <g><path style="opacity:1" fill="#c8594d" d="M 185.5,158.5 C 190.833,158.5 196.167,158.5 201.5,158.5C 201.5,163.833 201.5,169.167 201.5,174.5C 201.5,169.167 201.5,163.833 201.5,158.5C 196.167,158.5 190.833,158.5 185.5,158.5 Z"/></g>
          <g><path style="opacity:1" fill="#d3d3d3" d="M 208.5,158.5 C 214.167,158.5 219.833,158.5 225.5,158.5C 225.5,164.167 225.5,169.833 225.5,175.5C 219.833,175.5 214.167,175.5 208.5,175.5C 208.5,169.833 208.5,164.167 208.5,158.5 Z"/></g>
          <g><path style="opacity:1" fill="#d3d3d3" d="M 255.5,158.5 C 261.167,158.5 266.833,158.5 272.5,158.5C 272.5,164.167 272.5,169.833 272.5,175.5C 266.833,175.5 261.167,175.5 255.5,175.5C 255.5,169.833 255.5,164.167 255.5,158.5 Z"/></g>
          <g><path style="opacity:1" fill="#d2d2d2" d="M 279.5,158.5 C 284.833,158.5 290.167,158.5 295.5,158.5C 295.5,164.167 295.5,169.833 295.5,175.5C 290.167,175.5 284.833,175.5 279.5,175.5C 279.5,169.833 279.5,164.167 279.5,158.5 Z"/></g>
          <g><path style="opacity:1" fill="#d3d3d3" d="M 302.5,158.5 C 308.167,158.5 313.833,158.5 319.5,158.5C 319.5,164.167 319.5,169.833 319.5,175.5C 313.833,175.5 308.167,175.5 302.5,175.5C 302.5,169.833 302.5,164.167 302.5,158.5 Z"/></g>
          <g><path style="opacity:1" fill="#c8594d" d="M 342.5,158.5 C 342.5,163.833 342.5,169.167 342.5,174.5C 337.167,174.5 331.833,174.5 326.5,174.5C 326.5,169.167 326.5,163.833 326.5,158.5C 331.833,158.5 337.167,158.5 342.5,158.5 Z"/></g>
          <g><path style="opacity:1" fill="#dc9189" d="M 185.5,158.5 C 185.5,163.833 185.5,169.167 185.5,174.5C 190.833,174.5 196.167,174.5 201.5,174.5C 196.025,175.489 190.358,175.822 184.5,175.5C 184.178,169.642 184.511,163.975 185.5,158.5 Z"/></g>
          <g><path style="opacity:1" fill="#dc9189" d="M 342.5,158.5 C 343.489,163.975 343.822,169.642 343.5,175.5C 337.642,175.822 331.975,175.489 326.5,174.5C 331.833,174.5 337.167,174.5 342.5,174.5C 342.5,169.167 342.5,163.833 342.5,158.5 Z"/></g>
          <g><path style="opacity:1" fill="#0f3f6f" d="M 251.5,176.5 C 252.659,178.25 254.326,179.25 256.5,179.5C 261.516,182.674 266.849,185.341 272.5,187.5C 275.102,189.142 277.769,190.808 280.5,192.5C 279.141,194.692 277.308,196.359 275,197.5C 274.333,198.833 274.333,200.167 275,201.5C 279.534,205.699 283.701,210.199 287.5,215C 285.333,217.167 283.167,219.333 281,221.5C 276.323,215.991 271.49,210.658 266.5,205.5C 263.849,207.65 261.349,209.983 259,212.5C 255.371,202.983 252.204,193.316 249.5,183.5C 248.833,181.167 248.167,178.833 247.5,176.5C 248.632,175.646 249.965,175.646 251.5,176.5 Z"/></g>
          <g><path style="opacity:1" fill="#e09f98" d="M 201.5,180.5 C 196.167,180.5 190.833,180.5 185.5,180.5C 185.5,185.833 185.5,191.167 185.5,196.5C 184.511,191.025 184.178,185.358 184.5,179.5C 190.358,179.178 196.025,179.511 201.5,180.5 Z"/></g>
          <g><path style="opacity:1" fill="#c8594d" d="M 201.5,180.5 C 201.5,185.833 201.5,191.167 201.5,196.5C 196.167,196.5 190.833,196.5 185.5,196.5C 185.5,191.167 185.5,185.833 185.5,180.5C 190.833,180.5 196.167,180.5 201.5,180.5 Z"/></g>
          <g><path style="opacity:1" fill="#d2d2d2" d="M 208.5,179.5 C 213.833,179.5 219.167,179.5 224.5,179.5C 224.5,185.167 224.5,190.833 224.5,196.5C 219.167,196.5 213.833,196.5 208.5,196.5C 208.5,190.833 208.5,185.167 208.5,179.5 Z"/></g>
          <g><path style="opacity:1" fill="#d3d2d2" d="M 247.5,176.5 C 248.167,178.833 248.833,181.167 249.5,183.5C 248.515,187.634 248.182,191.968 248.5,196.5C 243.167,196.5 237.833,196.5 232.5,196.5C 232.5,190.5 232.5,184.5 232.5,178.5C 235.505,180.299 238.838,180.966 242.5,180.5C 242.5,179.167 242.5,177.833 242.5,176.5C 235.652,176.651 231.985,173.318 231.5,166.5C 233.143,158.51 237.81,156.01 245.5,159C 250.451,164.005 250.784,169.339 246.5,175C 246.57,175.765 246.903,176.265 247.5,176.5 Z"/></g>
          <g><path style="opacity:1" fill="#cbcdd0" d="M 256.5,179.5 C 261.833,179.5 267.167,179.5 272.5,179.5C 272.5,182.167 272.5,184.833 272.5,187.5C 266.849,185.341 261.516,182.674 256.5,179.5 Z"/></g>
          <g><path style="opacity:0.998" fill="#dadedf" d="M 120.5,268.5 C 216.5,268.5 312.5,268.5 408.5,268.5C 409.126,278.595 408.459,288.428 406.5,298C 371.168,298.5 335.835,298.667 300.5,298.5C 277.167,298.5 253.833,298.5 230.5,298.5C 194.498,298.667 158.498,298.5 122.5,298C 121.874,297.25 121.374,296.416 121,295.5C 120.5,286.506 120.334,277.506 120.5,268.5 Z"/></g>
          <g><path style="opacity:1" fill="#8a898d" d="M 262.5,278.5 C 270.379,278.216 273.212,281.883 271,289.5C 266.098,293.986 262.098,293.319 259,287.5C 258.333,285.833 258.333,284.167 259,282.5C 260.376,281.295 261.542,279.962 262.5,278.5 Z"/></g>
          <g><path style="opacity:0.988" fill="#a7c2c4" d="M 230.5,298.5 C 253.833,298.5 277.167,298.5 300.5,298.5C 301.312,307.853 302.312,317.187 303.5,326.5C 278.167,326.5 252.833,326.5 227.5,326.5C 228.312,317.147 229.312,307.813 230.5,298.5 Z"/></g>
          <g><path style="opacity:0.987" fill="#dadfdf" d="M 227.5,326.5 C 252.833,326.5 278.167,326.5 303.5,326.5C 308.057,327.557 310.723,330.39 311.5,335C 280.168,335.5 248.835,335.667 217.5,335.5C 217.604,329.067 220.937,326.067 227.5,326.5 Z"/></g>
          </svg>`,
        width: 50,
        height: 50,
        style: 'logo'
      },
      { text: 'Agendamento Online', style: 'titlePrinciapal' },
      // { text: 'Avenida 7 de Setembro, 237 - Esquina com Avenida Farquar', style: 'subTitlePrinciapal' },
      { text: `${props.agendamento.servico.unidade.descricao}`, style: 'subTitlePrinciapal' },

      { text: '_________________________________________________________________________', style: 'logo' },

      { text: 'COMPROVANTE DE AGENDAMENTO', style: 'titulo', margin: [ 0, 25, 0, 30 ] },
      
      // margin: [left, top, right, bottom]

      { text: `Protocolo de atendimento: ${props.slug}`, margin: [ 0, 15, 0, 15 ], bold: true },

      {
        // text: [
        //   { text: `Senhor(a) ${props.nome}, seu agendamento foi realizado com sucesso!! Seu protocolo de atendimento é ${props.slug}, e está agendado para o dia ${moment(props.horario_agendado.data).format('DD-MM-YYYY')} às ${props.horario_agendado.hora.substring(0, props.horario_agendado.hora.length - 3)} na ${props.horario_agendado.unidade.descricao} situado na ${props.horario_agendado.unidade.logradouro}, ${props.horario_agendado.unidade.numero}, ${props.horario_agendado.unidade.bairro}.`, style: 'body' },
        // ]
        text: [
          { text: `O(A) senhor(a) ${props.nome} agendou para o dia ${moment(props.agendamento.data).format('DD.MM.YYYY')} às ${props.agendamento.hora}, o atendimento presencial na ${props.agendamento.servico.unidade.descricao}, situada na ${props.agendamento.servico.unidade.logradouro}, ${props.agendamento.servico.unidade.numero}, ${props.agendamento.servico.unidade.bairro}, para tratar de assuntos relacionados a ${props.agendamento.servico.descricao}.`, style: 'body' },
        ]
      },

      { text: props.agendamento.servico.observacoes && props.agendamento.servico.observacoes, style: 'body', bold: true },

      { text: 'Declara ainda está ciente de que:', style: 'body', margin: [ 0, 15, 0, 15 ] },

      {
        // to treat a paragraph as a bulleted list, set an array of items under the ul key
        ul: informativo.map((item, key) => (
          { key: key, text: item, style: 'body' }
        ))
      },

      { 
        
        text: `Impresso em: ${moment(new Date()).format('LLLL')}`,
        alignment: 'right', fontSize: 9,
        margin: [ 0, 50, 0, 0 ]
      
      }

    ],
    styles: {
      logo: {
        alignment: 'center',
      },
      titlePrinciapal: {
        fontSize: 13,
        alignment: 'center',
      },
      subTitlePrinciapal: {
        fontSize: 11,
        alignment: 'center',
      },
      titulo: {
        fontSize: 14,
        bold: true,
        background: '#CFCFCF',
        alignment: 'center',
      },
      body: {
        fontSize: 10, 
        lineHeight: 1.5, 
        alignment: 'justify',
      }
    }
  };

  pdfMake.createPdf(documentDefinition).open();
    
}