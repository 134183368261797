import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

// import { Container } from './styles';

const styles = makeStyles((theme) => ({
    page: {
        fontSize: '5rem',
        '@media (min-width:600px)': {
          fontSize: '10rem',
        },
    },
    subPage: {
        fontSize: '1.5rem',
        '@media (min-width:600px)': {
          fontSize: '2rem',
        },
    }
}));

function Page404() {

    const history = useHistory();

    const classes = styles();
    
    return (
        <React.Fragment>
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="center" alignItems="center" css={{height: '60vh'}}>
                    <Box>
                        
                        <Typography component="div" variant="h1" align="center">
                            <Box lineHeight="normal" fontWeight="fontWeightBold" className={classes.page}>
                                404
                            </Box>
                            <Box lineHeight="normal" className={classes.subPage}>Página não encontrada!</Box>
                            <Box fontSize={14} p={2}>Desculpe, mas a página que está procurando não existe!</Box>
                            <Box lineHeight={1}>
                                <Button variant="outlined" color="primary" size="small" onClick={() => history.replace('/')}>Pagina Inicial</Button>
                            </Box>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default Page404;