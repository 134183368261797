import React from 'react';

import { Box, Checkbox, Container, Divider, Fade, FormControlLabel, Grid, List, ListItem, ListItemText, makeStyles, Paper, Typography } from '@material-ui/core';

import { UnidadeContext } from '../../../Providers/unidadeProviders';
import { informativo } from '../../../Components/Informativo';

const styles = makeStyles((theme) => ({
    checkbok: {
        marginLeft: theme.spacing(2)
    },
    divider: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    left: {
        marginLeft: theme.spacing(2)
    },
    informativoMessage: {
        fontSize: '0.7rem',
        '@media (min-width:600px)': {
            fontSize: '0.8rem',
        },
    }
}))

function Orientacoes() {

    const { checked, setChecked } = React.useContext(UnidadeContext);

    React.useEffect(() => {
        setChecked(false);
    }, [setChecked]);


    const classes = styles();

    return (
        <Fade in={true} timeout={700}>
            <Container maxWidth="sm" fixed>
                <Grid container>

                    <Grid item md={12} xs={12}>

                        <List component="nav" aria-label="secondary mailbox folders" subheader={<Typography component="div" color="primary" variant="overline"><Box fontWeight="fontWeightBold" fontSize={16} m={1} display="flex" justifyContent="center">Orientações Gerais</Box></Typography>} dense={true}>

                            <Divider className={classes.divider} />

                            <Paper elevation={0} style={{ height: 290, overflow: 'auto' }}>
                                {informativo.map((info, key) => (
                                    <ListItem button key={key}>
                                        <ListItemText primary={<Typography variant="body2" component="div" align="justify" className={classes.informativoMessage}><Box letterSpacing={0}>{info}</Box></Typography>} />
                                    </ListItem>
                                ))}
                            </Paper>
                        </List>

                        <FormControlLabel control={<Checkbox
                            value={checked}
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                            color="secondary"
                            className={classes.left}
                        />
                        } label="Li e concordo" />

                    </Grid>
                </Grid>
            </Container>
        </Fade>
    );
}

export default React.memo(Orientacoes);